import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import useSiteMetadata from '../hooks/use-site-metadata'
import { slide as Menu } from 'react-burger-menu'
import logo from '../assets/images/logos/logo-white-text.png'
import '../styles/menu.css'

const Header = () => {
  const {
    menuLinks,
    title,
    headerHeight,
    headerHeight700,
    headerHeight500,
  } = useSiteMetadata()
  return (
    <header
      css={`
        background-color: #2d476f;
        box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.35);
        z-index: 2;
        @media (max-width: 700px) {
          position: fixed;
          top: 0;
          width: 100%;
        }
      `}
    >
      <div
        css={`
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          max-width: 960px;
          height: ${headerHeight}px;
          padding: 1.45rem 1.0875rem;
          @media (max-width: 700px) {
            height: ${headerHeight700}px;
          }
          @media (max-width: 500px) {
            height: ${headerHeight500}px;
          }
        `}
      >
        <Link
          to="/"
          title={`${title} home`}
          css={`
            align-items: center;
            display: flex;
            border-bottom: none !important;
            text-decoration: none;
          `}
        >
          <img
            src={logo}
            alt=""
            css={`
              margin-bottom: 0;
              width: 340px;
              @media (max-width: 460px) {
                width: 80%;
              }
            `}
          />
          <h1 className="hide">Magnatome</h1>
        </Link>
        <menu
          css={`
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            @media (max-width: 700px) {
              display: none;
            }
          `}
        >
          {menuLinks.map(link => (
            <Link
              key={link.link}
              to={link.link}
              css={`
                color: #f3f3f3;
                font-size: 1.1rem;
                margin: 10px;
                padding: 5px;
                text-decoration: none;
                box-sizing: content-box;
                position: relative;
                :hover {
                  top: 0.025rem;
                  border-bottom: 0.05rem solid #e3e3e3;
                }
                :focus {
                  outline: none;
                  top: 0.025rem;
                  border-bottom: 0.05rem solid #e3e3e3;
                }
              `}
            >
              {link.name}
            </Link>
          ))}
        </menu>
        <Menu right>
          {menuLinks.map(link => (
            <Link to={link.link} key={link.link}>
              {link.name}
            </Link>
          ))}
        </Menu>
      </div>
    </header>
  )
}

Header.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.object),
}

Header.defaultProps = {
  menuLinks: [],
}

export default Header
