import React from 'react'
import { footerHeight, headerHeight700, headerHeight500 } from '../data/config'

const Footer = () => (
  <footer
    css={`
      align-items: center;
      background-color: #2d476f;
      color: #eee;
      display: flex;
      justify-content: center;
      height: ${footerHeight}px;
      padding: 1.0875rem 1.45rem;
      text-align: center;
      z-index: 1;
      position: relative;
      @media (max-width: 700px) {
        top: ${headerHeight700}px;
      }
      @media (max-width: 500px) {
        top: ${headerHeight500}px;
      }
    `}
  >
    <div
      css={`
        font-size: 0.85rem;
      `}
    >
      © {new Date().getFullYear()} Magnatome, LLC
      <br />
      Made in Colorado
    </div>
  </footer>
)

export default Footer
