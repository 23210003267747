import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import Footer from './footer'
import SEO from './seo'
import { headerHeight, headerHeight700, headerHeight500 } from '../data/config'
import '../styles/layout.css'

const Layout = ({ fluid, children }) => (
  <React.Fragment>
    <SEO />
    <Header />
    <main
      css={`
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: ${fluid ? 'unset' : '960px'};
        min-height: calc(100vh - ${headerHeight}px);
        padding: ${fluid ? 'unset' : '1.45rem 1.0875rem'};
        @media (max-width: 700px) {
          position: relative;
          top: ${headerHeight700}px;
          min-height: calc(100vh - ${headerHeight700}px);
        }
        @media (max-width: 500px) {
          top: ${headerHeight500}px;
          min-height: calc(100vh - ${headerHeight500}px);
        }
      `}
    >
      {children}
    </main>
    <Footer />
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
