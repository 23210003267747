module.exports = {
  defaultTitle: 'Magnatome',
  author: 'Magnatome, LLC',
  siteUrl: 'https://magnatome.com',
  defaultDescription:
    'Magnatome develops high-quality, custom web development projects for customers large and small.',
  googleAnalyticsID: 'UA-180750103-1',
  themeColor: '#3e6892',
  backgroundColor: '#1f5f8b',
  headerColor: '#3e6892',
  footerColor: 'linear-gradient(180deg, #3e6892 20%, #042946 100%)',
  footerColorDefault: '#042946',
  headerHeight: 130,
  headerHeight700: 110,
  headerHeight500: 90,
  footerHeight: 100,
  address: {
    city: 'Denver',
    region: 'Colorado',
    country: 'United States',
    zipCode: '01742',
  },
  contact: {
    email: 'info@magnatome.com',
    // phone: 'phone number',
  },
  foundingDate: '2019',
  recaptcha_key: '6LfCfs4UAAAAAJVn61TQcL882LOyMifpvl88PmLc',
  menuLinks: [
    // {
    //   name: 'Our Work',
    //   link: '/our-work'
    // },
    // {
    //   name: 'Services',
    //   link: '/services'
    // },
    {
      name: 'Contact Us',
      link: '/contact',
    },
  ],
}
